.game-box {
    width: 100%;
    min-width: 320px;
    margin: 0 auto;
    border: solid 1px var(--dark-gray);
    height: 100%;
    min-height: 300px;
}

.game-box-header {
    height: 40px;
    border-bottom: solid 1px var(--dark-gray);

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.game-box-wrapper {
    display: flex;
    flex-direction: column;
}

.game-box-header .game-box-header-item {

    display: inline-flex;
    align-items: center;

}

.game-box-header .game-box-header-item .value {

    margin-left: 10px;

}

.game-box-header .game-box-header-item img {
    height: 20px;
    margin-left: 10px;
}

.take-money-btn {
    max-width: calc(100% - 40px);
    margin: 0 auto;
}

.plant-item {
    position: relative;
    width: 200px;
    margin: 0 auto;
}

.plant-item .plant-item-img {
    margin-top: 20px;
    width: 100%;
}

.plant-item .plant-item-count {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--blue);
    color: var(--lightest-gray);
    min-width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 22px;
    border-radius: 50%;

}


.shop-item {
    width: 100px;
}

.shop-item h2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-shop-item-title img {
    height: 20px !important;
}

.shop-item .image {
    margin: 0 auto;
}


.fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    padding: 5px;
    margin-top: 10px;
}


.field-item {
    height: calc(100vw / 3 - 15px);
    border: solid 1px var(--dark-gray);
    background-color: var(--green);

    background-image: url("/public/images/field/ground.webp");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}

.field-item .seeded-plant {
    width: 80%;
    height: 80%;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.field-item .field-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vw / 3 - 15px);
    gap: 5px;
    position: relative;
}

.field-item .field-options .field-options-item {
    height: calc((100vw / 3 - 20px) / 2);
    background-color: rgba(109, 200, 42, 0.77);
    border: solid 1px #6DC82AC4;
    position: relative;
}

.field-item .field-options .field-options-item img {
    width: 90%;
    height: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.field-item .field-timer{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-image: url("/public/images/icons/timer.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
