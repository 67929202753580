.auth-content {
    width: 300px;
    background-color: var(--white);
    color: var(--black);
    margin: 0 auto;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.auth-content h2 {
    font-weight: bold;
}

.auth-form{

}