.App {
    padding: 10px
}

.game-home-logo {
    /*width: 100%;*/
    /*width: 20px;*/
    /*max-width: 120px;*/
    height: 30px;
    margin: 0 5px;
    /*margin: 0 auto;*/
    /*margin-bottom: -5px;*/
    /*display: block;*/
    /*margin-bottom: 30px;*/
}