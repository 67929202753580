@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

* {
    font-family: "Itim", cursive;
    font-weight: 400;
    font-style: normal;

    margin: 0;
    padding: 0;

    box-sizing: border-box;
}

html {
    min-height: 100%;
    height: 100%;
    background-color: var(--black);
    color: var(--white);
}

body, #root, .App {
    height: inherit;
}

:root {
    --black: #000;
    --white: #fff;
    --green: #6dc82a;
    --blue: #057ec9;
    --lightest-gray: #efefef;
    --light-gray: #c9c9c9;
    --gray: #a6a6a6;
    --dark-gray: #797979;

    --purple: #8625c0;
    --error-red: #e3236c;
    --success-green: #60d012;
}


/*Paddings*/
.pb-10 {
    padding-bottom: 10px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

/*End Paddings*/

/*Margins*/
.mt-10 {
    margin-top: 10px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

/*End Margins*/

/*Text*/
.text-center {
    text-align: center;
}

.flex {
    display: flex;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.text-justify {
    text-align: justify;
}

/*End Text*/


/*Inputs*/
.row {
    margin: 10px 0;
}

.row label {
    display: block;
    font-size: 14px;
}

.row input {
    height: 40px;
    font-size: 16px;
    border-radius: 5px;
    border: solid 1px var(--light-gray);
    padding: 10px;
    width: 100%;
}

input:focus,
button:focus {
    outline: #6dc82a solid 2px;
}


button.btn {
    height: 40px;
    width: 100%;
    font-size: 18px;
    border: solid 1px var(--light-gray);
    border-radius: 5px;
    background-color: var(--lightest-gray);
    cursor: pointer;
    transition: all .4s;
}

button.btn:hover {
    border: solid 1px var(--gray);
}

button.btn.btn-success {
    height: 40px;
    width: 100%;
    font-size: 18px;
    border: solid 1px var(--green);
    border-radius: 5px;
    background-color: var(--green);
    cursor: pointer;
    color: var(--white);
    transition: all .4s;
}

button.btn.btn-success:hover {
    border: solid 1px var(--green);
}

button.btn.btn-info {
    height: 40px;
    width: 100%;
    font-size: 18px;
    border: solid 1px var(--blue);
    border-radius: 5px;
    background-color: var(--blue);
    cursor: pointer;
    color: var(--white);
    transition: all .4s;
}

button.btn.btn-info:hover {
    border: solid 1px var(--blue);
}

/*End Inputs*/


.error-msg-row {
    font-size: 12px;
    color: var(--error-red);
}

.error-message {
    font-size: 16px;
    color: var(--error-red);
}

.success-message {
    font-size: 16px;
    color: var(--success-green);
}


.w-100 {
    width: 100%;
}

.ReactModal__Content {
    color: var(--dark-gray);
}

.ReactModal__Content .modal-header {
    position: relative;
    display: block;
    height: 30px;
    border-bottom: solid 1px;
}

.ReactModal__Content .modal-header .close-modal-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    color: var(--error-red);
    font-size: 22px;
}

.ReactModal__Content .modal-content {

}
