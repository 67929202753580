.tabs-ik {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    /*border-radius: 10px;*/
    overflow: hidden;
    width: 300px;
}

.tabs-ik .tab-ik {
    padding: 10px 20px;
    height: 50px;
    background-color: var(--black);
    color: var(--white);

    font-size: 22px;
    flex: 1;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;

    border: solid 1px var(--purple);
}

.tabs-ik .tab-ik:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.tabs-ik .tab-ik:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tabs-ik .tab-ik > span {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    line-height: 50px;
}

.tabs-ik .tab-ik:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    background-size: 80%;
    background-position: center top;
    background-repeat: no-repeat;
    /*bottom: -100px;*/
    bottom: -85px;
    left: 50%;
    transform: translateX(-50%);
    transition: all .5s;
}

.tabs-ik .tab-ik:first-child:after {
    background-image: url(/public/images/sign-in-tab-bg.png);
}

.tabs-ik .tab-ik:last-child:after {
    background-image: url(/public/images/sign-up-tab-bg.png);
}

.tabs-ik .tab-ik.active {
    background-color: var(--purple);
}

.tabs-ik .tab-ik.active:after {
    bottom: -30px;
}

.tabs-ik .tab-ik.active {
    color: #fff;
}

.tabs-ik .tab-ik.active span {
    font-weight: bold;
}
